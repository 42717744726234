// $(document).ready(function() {
//     initHeight1()
// })
// function initHeight1() {
//     App.equalHeightElement('.special-news-item figure .image')
// }
$(document).ready(function() {
    changeImgMaps()
})

function changeImgMaps(){
    $('.select-khu').on('change', function(){
        if($(this).val() == 0 ){
            $('.select-tang').addClass('hidden')
            $('.select-tang:first-child()').removeClass('hidden')
        }else{
            $('.select-khu option').each(function() {
                if($(this).is(':selected')){
                    $(this).attr('selected', 'selected')
                }else{
                    $(this).removeAttr('selected')
                }
            })
            var imgUrl = $('.select-khu option[selected]').attr('data-img')
            $('.huong-dan-2 .content-wrap img').attr('src', imgUrl)
            var idSelectTang = $('option[selected]').attr('data-id')
            $('.select-tang').addClass('hidden')
            $('.select-tang[data-select-id='+ idSelectTang +']').removeClass('hidden')
        }
    })
    $('.select-tang').on('change', function(){
        $('.select-tang option').each(function() {
            if($(this).is(':selected')){
                $(this).attr('selected', 'selected')
            }else{
                $(this).removeAttr('selected')
            }
        })
        var imgUrl2 = $('.select-tang option[selected]').attr('data-img-2')
        $('.huong-dan-2 .content-wrap img').attr('src', imgUrl2)
    })
}